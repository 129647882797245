$family-sans-serif: Marcellus, Optima, sans-serif;
$text: #373435;
$tw-highlight: #a7518a;
//@import "./node_modules/bulma/sass/utilities/_all.sass";
//@import "./node_modules/bulma/sass/layout/_all.sass";
//@import "./node_modules/bulma/sass/elements/_all.sass";
//@import "./node_modules/bulma/sass/base/_all.sass";
@import "./node_modules/bulma/bulma.sass";
//@import url('https://fonts.googleapis.com/css?family=Marcellus&display=swap');

.bg-img {
    background: rgb(255,255,255);
    background: linear-gradient(278deg, rgba(230,220,232,1) 0%, rgba(223,232,238,1) 60%, rgba(175,183,177,1) 85%, rgba(83,89,60,1) 100%);
}
h1.title:after {
    content:' ';
    display:block;
    border:2px solid $tw-highlight;
}
a.navbar-item:hover {
    color: $tw-highlight;
}
ul.bullet li:before {
    content: "\25BA \0020";
}
p {
    margin-bottom: 1em;
}

@media screen and (max-width: 500px), screen and (min-width: $tablet) {
    .is-wide-mobile-only {
        display: none !important;
    }
}

a.no-highlight {
    color: $text;
}
